import { Controller } from "stimulus"

export default class extends Controller {

  read() {
    let itemId = this.element.dataset.id
    let itemType = this.element.dataset.itemType
    let xhr = new XMLHttpRequest();
    let unreadMarker = this.element.children[0]
    xhr.open('POST', document.location.origin + `/${itemType}/` + itemId + '/mark-as-read');
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.send('id=' + itemId);
    if (unreadMarker) unreadMarker.remove();
  }

  updateCounter() {
    let itemType = this.element.dataset.itemType;
    let read = this.element.dataset.read;
    let counter = document.getElementById(itemType + 'Counter');
    let unreadMarker = this.element.parentElement.querySelectorAll('.unread-marker')[0]
    if (read != "true" && counter) {
      let intCounter = parseInt(counter.innerText)
      if (intCounter === 1) {
        counter.remove();
        if (unreadMarker) unreadMarker.remove();
        this.element.setAttribute('data-read','true');
      } else {
        counter.innerText = (parseInt(counter.innerText) - 1).toString();
        if (unreadMarker) unreadMarker.remove();
        this.element.setAttribute('data-read','true');
      }
    }
  }

  get itemType() {

  }
}
