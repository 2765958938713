import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'keywords',
    'suggestion'
  ];

  connect() {
    this.suggestionTargets.forEach((suggestion) => {
      if (this.keywords().includes(suggestion.innerText)) {
        suggestion.classList.add('active');
      }
    })
  }

  toggle(e){
    e.currentTarget.classList.toggle('active');
    const keyword = e.currentTarget.innerText
    const keywords = this.keywords()
    if (keywords.includes(keyword)) {
      keywords.splice(keywords.indexOf(keyword), 1);
    } else {
      keywords.push(keyword)
    }
    this.keywordsTarget.value = keywords.filter(function(el) { return el != ''; }).join(',')
  }

  keywords(){
    return this.keywordsTarget.value.split(',')
               .map((keyword) => keyword.replace(/^\s+/g, ''))
  }
}
