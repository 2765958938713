import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'tag',
    'switch'
  ];

  connect(){
    if (this.hasSwitchTarget) {
      this.keywordMatch({
        detail: this.switchTarget.innerHTML
      })
    }
  }

  keywordMatch(e){
   const text = e.detail

   this.tagTargets.forEach((tag) => {
      const keyword = tag.innerText
      var regExPattern = '\\b'+keyword+'\\b'
      tag.classList.toggle('active', text.match(new RegExp(regExPattern, 'gi'))
      )
    })
  }
}
