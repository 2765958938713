function updateChildren(parent){
  document.querySelectorAll('.semrush_campaign_field_child').forEach((child) => {
    console.log(child.value)
    child.value = parent.value
  })
}

function semrushCampaignFieldSynchroniser(){
  document.addEventListener("DOMContentLoaded", function() {
    if (document.querySelector('#semrush_campaign_field_parent')){
      const parent = document.querySelector('#semrush_campaign_field_parent')
      parent.oninput = function() {
        updateChildren(parent)
      };
    }
  })
}

export { semrushCampaignFieldSynchroniser };
