// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["form"];

  connect() {
    var form = this.element.children[0];
    var content = form.querySelector("#article_content");
    console.log("connected");
    if (this.hasFormTarget) {
      console.log(this.formTarget);
    } else {
      console.log("form target not found");
    }
  }

  saveArticle() {
    console.log("saved");
  }

  openItem() {}

  updateUrlForItem(event) {
    var itemId = event.currentTarget.dataset.id;
    var params = new URLSearchParams(location.search);
    if (params.get("open_id") != null) {
      params.delete("open_id");
    }
    params.set("open_id", itemId);
    window.history.replaceState(
      {},
      "",
      decodeURIComponent(`${location.pathname}?${params}`)
    );
  }

  updateUrlForTabs(event) {
    var tab = event.currentTarget.dataset.tab;
    var params = new URLSearchParams(location.search);
    if (params.get("tab") != null) {
      params.delete("tab");
    }
    params.set("tab", tab);
    window.history.replaceState(
      {},
      "",
      decodeURIComponent(`${location.pathname}?${params}`)
    );
  }

  updateStatus(event) {
    event.preventDefault();

    const form = this.formTarget;
    const url = form.action;
    const formData = new FormData(form);

    fetch(url, {
      method: "PUT",
      headers: {
        "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          console.log("Status updated successfully");
          this.showSuccessMessage("Status updated successfully");
          window.location.reload();
          // Optionally, update the UI to reflect the new status
        } else {
          console.error("Failed to update status");
          this.showErrorMessage("Failed to update status");
        }
      })
      .catch((error) => {
        console.error("Error updating status:", error);
        this.showErrorMessage("An error occurred while updating status");
      });
  }

  showSuccessMessage(message) {
    const successMessage = document.createElement("div");
    successMessage.className = "alert alert-success";
    successMessage.innerText = message;
    document.body.appendChild(successMessage);
    setTimeout(() => {
      successMessage.remove();
    }, 3000); // Remove after 3 seconds
  }

  showErrorMessage(message) {
    const errorMessage = document.createElement("div");
    errorMessage.className = "alert alert-danger";
    errorMessage.innerText = message;
    document.body.appendChild(errorMessage);
    setTimeout(() => {
      errorMessage.remove();
    }, 3000); // Remove after 3 seconds
  }
}
