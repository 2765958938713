import { Controller } from "stimulus"

export default class extends Controller {

  onPostSuccess(event) {
    event.target.innerHTML = "<button class=\"btn-link\" disabled>Lien crée</button>"
    event.target.disabled = true
  }

}
