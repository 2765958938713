import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    var form = this.element.children[0];
    var content = form.querySelector("#article_content");
  }

  saveArticle() {
    console.log('saved');
  }
}
