import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'loader',
    'cta',
  ];

  displaySpinner(){
    this.ctaTarget.classList.add('hidden')
    this.loaderTarget.classList.remove('hidden')
  }
}
