import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["newCommentBody", "newCommentForm", "submitButton"];

  connect() {
    this.newCommentBodyTarget.addEventListener('keyup', this.toggleSubmitButton);
    this.newCommentFormTarget.addEventListener('submit', this.disableSubmitButton);
    this.submitButtonTarget.style.display = "none";
  }

  toggleSubmitButton(e) {
    let length = this.value.length;
    let commentableId = this.dataset.id;
    let submitButton = document.getElementById('submit' + commentableId);
    let inactiveSubmitButton = document.getElementById('inactiveSubmitButton' + commentableId);

    if (length) {
      submitButton.style.display = "inline-block";
      inactiveSubmitButton.style.display = "none";
    } else {
      submitButton.style.display = "none";
      inactiveSubmitButton.style.display = "inline-block";
    }
  }

  disableSubmitButton(e) {
    let commentableId = this.dataset.id;
    let submitButton = this.querySelectorAll("input[type=submit]")[0];
    let inactiveSubmitButton = document.getElementById('inactiveSubmitButton' + commentableId);
    submitButton.style.display = "none";
    inactiveSubmitButton.style.display = "inline-block";
  }
}
