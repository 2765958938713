import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'loader1',
    'loader2',
    'cta2'
    // 'cta1'
  ];

  displaySpinner1(){
    // this.cta1Target.classList.add('hidden')
    this.loader1Target.classList.remove('hidden')
  }

  displaySpinner2(){
    this.cta2Target.classList.add('hidden')
    this.loader2Target.classList.remove('hidden')
  }
}
