import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'list'
  ]

  disableInput(e) {
    if (e.target.checked) {
      this.listTarget.disabled = true;
    } else {
      this.listTarget.disabled = false;
    }
  }
}
