import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'loader',
    'form',
    'submit'
  ]

  submit(){
    this.formTarget.submit()
    this.hideForm()
    this.showLoader()
  }

  hideForm(){
    this.formTarget.classList.add('hidden')
  }

  showLoader(){
    this.loaderTarget.classList.remove('hidden')
  }
}
