import { Controller } from "stimulus"
export default class extends Controller {
  static targets = ["form"]
  submit(event) {
    event.preventDefault();
    const form = event.target.form;
    const url = form.action;
    const method = form.method;
    const formData = new FormData(form);
    fetch(url, {
      method: method,
      body: formData,
      headers: {
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content
      },
      credentials: 'same-origin'
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
    })
    .catch(error => {
      console.error(error);
    });
  }
}