import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "change",
    "toggleBtn"
  ]

  admintoggle() {
      this.changeTargets.forEach((element) => {
        element.classList.toggle('collapsed');
      })

      this.toggleBtnTargets.forEach((toggleBtn) => {
        toggleBtn.classList.toggle('hidden');
      })
  }
}
