import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.has('message')) {
      let messageId = urlParams.get('message')
      let message = document.getElementById('item_' + messageId)
      let previous_message = message.parentElement.parentElement.parentElement.previousElementSibling
      console.log(previous_message)
      if (message) {
        if (previous_message) previous_message.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
        message.click();
        this.markAsRead(message);
        this.updateCounters();
      }
    }
  }

  markAsRead(message) {
    let itemId = message.dataset.id
    let itemType = message.dataset.itemType
    let xhr = new XMLHttpRequest();
    let unreadMarker = message.children[0]
    xhr.open('POST', document.location.origin + `/${itemType}/` + itemId + '/mark-as-read');
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.send('id=' + itemId);
    if (unreadMarker) unreadMarker.remove();
  }

  updateCounters() {
    let counters = document.querySelectorAll('.messages-counter')
    for (var i = 0; i < counters.length; i++) {
      var currentCount = counters[i].innerText
      if (currentCount > "1") {
        counters[i].innerText = (parseInt(counters[i].innerText) - 1).toString()
      } else {
        counters[i].remove()
      }
    }
  }
}
